<template>
  <div class="DestinationGuides">

    <div class="section1">
      <div class="itemLeft d-lg-none d-block">
        <h1 class="">Destination</h1>
        <h1 class="">Guides</h1>
        <div class="hr"></div>
        <p>
          With so many options available, it can be difficult to decide where to start your next adventure.
          We have exciting opportunities in Hong Kong and Macau with endless possibilities for exploring Asia!
        </p>
      </div>
      <img class="mw-100 d-lg-block d-none" src="../../assets/images/destination-guides/Asset 24.png" alt="">
      <div class="d-lg-none d-block" style="position: relative">
        <img class="mw-100" src="../../assets/images/destination-guides/mobile_Asset 37.png" alt="">
        <div class="btn-box">
          <button class="left-btn" @click="goToHongKong">Hong Kong</button>
          <button class="right-btn active" @click="goToMacau">Macau</button>
        </div>
      </div>
      <div class="itemLeft d-lg-block d-none">
        <h1 class="">Destination</h1>
        <h1 class="">Guides</h1>
        <div class="hr"></div>
        <p>
          With so many options available, it can be difficult to decide where to start your next adventure.
          We have exciting opportunities in Hong Kong and Macau with endless possibilities for exploring Asia!
        </p>
        <div class="btn-box">
          <button class="left-btn" @click="goToHongKong">Hong Kong</button>
          <button class="right-btn active" @click="goToMacau">Macau</button>
        </div>
      </div>
    </div>

    <div class="section2">
      <img class="mw-100 header-img d-lg-block d-none" src="../../assets/images/destination-guides/macau_Asset 31.png" alt="">
      <img class="mw-100 header-img d-lg-none d-block" src="../../assets/images/destination-guides/mobile_Asset 45.png" alt="">
      <div class="center-box">
        <h1 class="">Macau</h1>
        <p>
          Macau is an independent region on the south coast of China, across the Pearl River Delta from Hong Kong.
          A Portuguese terrain until 1999, it reflects a mix of cultural influences. Its giant casinos and malls on the Cotai Strip,
          which joins the islands of Taipa and Coloane, have earned it the nickname, “Las Vegas of Asia.”
        </p>
      </div>
    </div>

    <div class="section3">
      <div class="section3-1">
        <div class="left-box macauBox">
          <div class="item">
            <h1 class="">Ruins of Saint Paul’s</h1>
            <p>
              The Ruins of Saint Paul’s are the ruins of a 17th-century Catholic religious complex in Santo António, Macau, China.
            </p>
          </div>
        </div>
        <div class="center-box">
          <img class="mw-100 d-lg-block d-none" src="../../assets/images/destination-guides/macau_Asset 32.png" alt="">
          <img class="mw-100 d-lg-none d-block" src="../../assets/images/destination-guides/mobile_Asset 51.png" alt="">
        </div>
        <div class="right-box">
          <div class="item">
            <h1 class="">A-Ma Temple</h1>
            <p>
              Approximately four hundred years ago the Portuguese landed on a sea promontory near a temple. They asked
              the residents the name of the land, but the locals misinterpreted, thinking that the Portuguese were asking
              for the name of the temple. So they answered ‘Ma Ge’. Later, the Portuguese translated the name into ‘Macau’ and used it to refer to the land.
            </p>
          </div>
        </div>
      </div>
      <div class="section3-2">
        <div class="left-box">
          <img class="mw-100 d-lg-block d-none" src="../../assets/images/destination-guides/macau_Asset 33.png" alt="">
          <img class="mw-100 d-lg-none d-block" src="../../assets/images/destination-guides/mobile_Asset 50.png" alt="">
        </div>
        <div class="right-box rightBox">
          <div class="item">
            <h1 class="d-lg-block d-none">Macau Fisherman’s</h1>
            <h1 class="d-lg-block d-none">Wharf</h1>
            <h1 class="d-lg-none d-block">Macau Fisherman’s Wharf</h1>
            <p>
              Located at Macau’s outer harbour, within a 5-minute walk from the Macau Ferry Terminal and Heliport,
              this is the largest leisure and themed entertainment complex in Macau Peninsula.
            </p>
          </div>
        </div>
      </div>
      <div class="section3-3">
        <div class="left-box leftBox">
          <img class="mw-100 d-lg-block d-none" src="../../assets/images/destination-guides/Asset 53_Macau_n.png" alt="">
          <img class="mw-100 d-lg-none d-block" src="../../assets/images/destination-guides/mobile_Asset 49.png" alt="">
        </div>
        <img class="mw-100 d-lg-none d-block" src="../../assets/images/destination-guides/mobile_Asset 46.png" alt="">
        <div class="right-box rightBox macau-item">
          <div class="item">
            <h1 class="">Cuisine</h1>
            <p>
              Macanese cuisine is unique to Macau, and consists of a blend of southern Chinese and Portuguese cuisines,
              with influences from Southeast Asia and the Lusophone world.
            </p>
          </div>
          <img class="mw-100 d-lg-block d-none" src="../../assets/images/destination-guides/macau_Asset 35.png" alt="">
          <img class="mw-100 d-lg-none d-block" src="../../assets/images/destination-guides/mobile_Asset 47.png" alt="">
        </div>
      </div>
      <div class="section3-1">
        <div class="right-box rightBox macau-box">
          <div class="item">
            <h1 class="">Weather</h1>
            <p>
              Having distinctive dry and wet seasons, Macau’s weather is generally warm and foggy in spring, hot and rainy in summer,
              cool and clear in autumn, and cold but sunny in winter. The average annual temperature is about 20°C (72°F)
              and there are about 100 days with high temperatures over 30°C (86°F).
            </p>
          </div>
          <div class="item">
            <h1 class="">Airports</h1>
            <p>Macau International Airport</p>
          </div>
        </div>
        <div class="center-box macau-box">
          <img class="mw-100 d-lg-block d-none" src="../../assets/images/destination-guides/macau_Asset 36.png" alt="">
          <img class="mw-100 d-lg-none d-block" src="../../assets/images/destination-guides/mobile_Asset 48.png" alt="">
        </div>
      </div>
    </div>

    <div class="section4">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-7 col-12">
            <h1>Country Facts - Cost Of Living</h1>
            <ul>
              <li>
                The cost of a combo meal at a fast-food restaurant in Macau starts at 40 MOP and a meal in a local restaurant can
                cost around 20 MOP. A weekly grocery shop can cost around 1,000 MOP.
              </li>
              <li>Utility costs can be between 330 RMB and 600 RMB per month.</li>
              <li>The cost of a gym membership is around 920 MOP per month.</li>
            </ul>
            <p class="macau-p macau-pt">
              Between 6,000 HKD and 7,500 HKD per month along with one month of rent as a deposit should you choose dorm living?
              In some cases, three months of rent is required upfront with a deposit should you wish to find your own apartment.
            </p>
            <p class="macau-p">+ The cost of living in Macau is still oddly cheaper compared to other similar expat hubs in Asia such as Hong Kong and Singapore.</p>
            <p class="macau-p">
              + The cost of living comparison of Numbeo shows that in all major cost of living indicators such as housing, food, transport, utilities,
              and purchasing power, Hong Kong and Singapore are significantly higher than Macau.
            </p>
          </div>
        </div>
      </div>
    </div>

    <Footer/>
  </div>
</template>

<script>
import Footer from "@/views/Layout/Footer.vue";
import store from "@/store";
export default {
  components: {Footer},
  data() {
    return {

    }
  },
  created() {
  },
  mounted() {

  },
  methods: {
    goToHongKong() {
      this.$router.push({path:'/DestinationGuides/HongKong',query:{name:'/DestinationGuides/HongKong'}})
    },
    goToMacau() {
      this.$router.push({path:'/DestinationGuides/Macau',query:{name:'/DestinationGuides/Macau'}})
    }
  }
}

</script>


<style scoped>

</style>