<template>
  <div class="Vacancies">
    <div class="section1">
      <div class="left_box">
        <div class="itemLeft">
          <h1 class="">Vacancies</h1>
          <div class="hr"></div>
          <p>
            We have full-time positions in learning centres across Hong Kong Macau with Monkey Tree English Learning Centres!
            In Macau we have 4 intakes a year so please get in touch if you would like more information on our upcoming start dates.
            Interested parties please apply by completing an online application form at below.
          </p>
          <p>
            If you would like more information on other locations, please enquire on our contact form <span @click="geToContact" style="color: #0d6efd;cursor: pointer">here.</span>
          </p>
        </div>
      </div>
      <div class="right_box">
        <img class="mw-100" src="../../assets/images/vacancies/top_img.png" alt="">
      </div>
    </div>

    <div class="section2">
      <div class="uli">
        <ul>
          <li v-show="isAll">Region</li>
          <li>Start Dates</li>
        </ul>
        <div class="box"></div>
      </div>
      <div class="all_box">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="All" name="first">
            <el-table :data="allVacanciesData" style="width: 100%;">
              <el-table-column prop="date" label="Region" width="60"></el-table-column>
              <el-table-column prop="name" label="Start Dates" width="310"></el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="Hong Kong" name="second">
            <el-table :data="hkVacanciesData" style="width: 100%;">
              <el-table-column prop="date" label="Region" width="60"></el-table-column>
              <el-table-column prop="name" label="Start Dates" width="310"></el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="Macau" name="third" disabled>Macau</el-tab-pane> <!-- disabled 禁用 -->
        </el-tabs>
      </div>
    </div>

     <div class="at_gtr">
       <p class="d-lg-block d-none">At GTR, we place ESL teachers in learning centres across Hong Kong and Macau. Learning centres are different from mainstream schools as they’re</p>
       <p class="d-lg-block d-none"> extra-curricular lessons which children take outside of their schooling to improve their English, which makes them a little more fun in comparison!</p>
       <p class="d-lg-none d-block">
         At GTR, we place ESL teachers in learning centres across Hong Kong and Macau. Learning centres are different from mainstream schools as they’re
         extra-curricular lessons which children take outside of their schooling to improve their English, which makes them a little more fun in comparison!
       </p>
     </div>

    <div class="section3">
       <div class="left_box">
         <h3>Before you go</h3>
         <div class="hr"></div>
         <ul>
           <li>We secure you a 1-year placement in our learning centres across Asia.</li>
           <li>Our dedicated team guides you through the visa process.</li>
           <li>Optional assistance with accommodation and set up before you head out.</li>
           <li>We cover the cost of your Hong Kong and Macau visa and full guidance is provided throughout the process of the application.</li>
         </ul>
       </div>
       <div class="right_box">
         <h3>In Asia</h3>
         <div class="hr"></div>
         <ul>
           <li>Support provided by our in-country support team.</li>
           <li>Training on arrival with lesson observation in the learning centres.</li>
           <li>There is a tailor-made curriculum including lesson plans and any accompanying materials required to allow you to solely focus on your teaching.</li>
           <li>Competitive salaries for your placement (Between HKD 23000 per month working in Hong Kong and between MOP21000 - MOP22000 month working in Macau).</li>
           <li>Small class sizes of 4-12 students.</li>
           <li>Maximum of 26 teaching hours a week for Hong Kong and 28 hours a week for Macau.</li>
           <li>2 days off per week.</li>
           <li>All national public holidays off and assigned annual leave.</li>
           <li>Contract completion bonus for all locations.</li>
           <li>Opportunity to renew your contract for another year and change location after your first year.</li>
         </ul>
       </div>
    </div>

    <div class="section4">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-12 left_box">
            <div class="item">
              <h1>Join Us</h1>
              <p>Work Overseas Keep</p>
              <p>Travelling The World</p>
            </div>
          </div>
          <div class="col-lg-8 col-12 right_box">
            <div class="col-12 item_p">
              <p>Looking for answers or unsure about the procedure involved with teaching abroad?</p>
              <p>Simply complete the contact form and we will get back to you!</p>
            </div>
            <div class="col-12 sel_box">
              <div class="col-6">
                <div class="inputBox">
                  <span>Last name:</span>
                  <el-input class="inputItem" id="last_name" v-model="selData.last_name" @input="getLastName" placeholder=""></el-input>
                  <i v-show="isLast_name" class="el-icon-warning"></i>
                </div>

                <div class="inputBox nation">
                  <el-select v-model="selData.nationality" @input="getNationality" placeholder="">
                    <el-option v-for="(item,index) in country" :label="item.value" :value="item.value" :key="index"></el-option>
<!--                    <el-option label="Macau" value="2"></el-option>-->
                  </el-select>
                  <span>Nationality:<i v-show="isNationality" class="nation-war el-icon-warning"></i></span>
                </div>

                <div class="inputBox">
                  <span>Phone:</span>
                  <el-input class="inputItem" id="phone" v-model="selData.phone" @input="getPhone" placeholder=""></el-input>
                  <i v-show="isPhones" class="el-icon-warning"></i>
                </div>

                <div class="inputBox  nation nation02">
                  <el-select v-model="selData.marital_status" @input="getMarital" placeholder="">
                    <el-option label="Single" value="Single"></el-option>
                    <el-option label="Married" value="married"></el-option>
                  </el-select>
                  <span>Marital status:<i v-show="isMarital" class="nation-war el-icon-warning"></i></span>
                </div>

                <div class="inputBox">
                  <span>TEFL certificate held:</span>
                  <el-input class="inputItem inputItem01" id="tefl_certificate_held" v-model="selData.tefl_certificate_held" @input="getTefl"  placeholder=""></el-input>
                  <i v-show="isTefl" class="el-icon-warning"></i>
                </div>

                <div class="inputBox">
                  <span>Career level:</span>
                  <el-input class="inputItem" id="career_level" v-model="selData.career_level" @input="getCareer"  placeholder=""></el-input>
                  <i v-show="isCareer" class="el-icon-warning"></i>
                </div>

<!--                <div class="inputBox career">-->
<!--                  <el-select v-model="selData.career_level" @input="getCareer" placeholder="">-->
<!--                    <el-option v-for="(item,index) in allItems3" :label="item" :value="item" :key="index"></el-option>-->
<!--                  </el-select>-->
<!--                  <span>Career level:<i v-show="isCareer" class="career-war el-icon-warning"></i></span>-->
<!--                </div>-->

<!--                <div class="inputBox sectors">-->
<!--                  <div class="TopBox">-->
<!--                    <span>Sectors:</span>-->
<!--                    <span class="span" v-show="!isSelect6">Select some options.</span>-->
<!--                    <i v-show="isSectors" class="location-war el-icon-warning"></i>-->
<!--                  </div>-->
<!--                  <div class="itemBox" v-show="isSelect6">-->
<!--                    <ul class="uli-Box" >-->
<!--                      <li v-for="(item, index) in items1" :key="index">-->
<!--                        {{ item }}-->
<!--                        <button @click="removeItem1(index)">X</button>-->
<!--                      </li>-->
<!--                    </ul>-->
<!--                  </div>-->
<!--                  <el-select v-model="select6"  placeholder=" ">-->
<!--                    <el-option v-for="(item,index) in allItems1" :key="index" :label="item" :value="index"></el-option>-->
<!--                  </el-select>-->
<!--                </div>-->

                <div class="inputBox textarea">
                  <span>Region you reside in:</span>
                  <el-input
                      type="textarea"
                      :autosize="{ minRows: 6, maxRows: 6}"
                      placeholder="Please input region you reside in."
                      v-model="selData.region_you_reside_in"
                      @input="getRegion"
                  >
                  </el-input>
                  <i style="margin-top: 1%" class="el-icon-warning" v-show="isRegion"></i>
                </div>
              </div>

              <div class="col-6">
                <div class="inputBox">
                  <span>Surname:</span>
                  <el-input class="inputItem" id="surname" v-model="selData.surname" @input="getSurname" placeholder=""></el-input>
                  <i v-show="isSurname" class="el-icon-warning"></i>
                </div>

                <div class="inputBox">
                  <span>ID no.:</span>
                  <el-input class="inputItem" id="ID_no" v-model="selData.id_no" @input="getIDno" placeholder=""></el-input>
                  <i v-show="isId_no" class="el-icon-warning"></i>
                </div>

                <div class="inputBox">
                  <span>Email:</span>
                  <el-input class="inputItem" id="email" v-model="selData.email" @input="getEmail"  placeholder=""></el-input>
                  <i v-show="isEmail" class="el-icon-warning"></i>
                </div>

                <div class="inputBox">
                  <span>Education level:</span>
                  <el-input class="inputItem inputItem01" id="education_level" v-model="selData.education_level" @input="getEducation"  placeholder=""></el-input>
                  <i v-show="isEducation" class="el-icon-warning"></i>
                </div>

<!--                <div class="inputBox education">-->
<!--                  <el-select v-model="selData.education_level" @input="getEducation" placeholder="">-->
<!--                    <el-option v-for="(item,index) in allItems4" :label="item" :value="item" :key="index"></el-option>-->
<!--                  </el-select>-->
<!--                  <span>Education level:<i v-show="isEducation" class="education-war el-icon-warning"></i></span>-->
<!--                </div>-->

                <div class="inputBox">
                  <span>Passport held:</span>
                  <el-input class="inputItem inputItem01" id="passport_held:" v-model="selData.passport_held" @input="getPassport" placeholder=""></el-input>
                  <i v-show="isPassport" class="el-icon-warning"></i>
                </div>

                <div class="inputBox location">
                  <el-select v-model="selData.location_interested_in" @input="getLocation" placeholder="">
                    <el-option label="Hong Kong" value="Hong Kong"></el-option>
                    <el-option label="Macau" value="Macau"></el-option>
                  </el-select>
                  <span>Location interested in:<i v-show="isLocation" class="location-war el-icon-warning"></i></span>
                </div>

<!--                <div class="inputBox sectors">-->
<!--                  <div class="TopBox">-->
<!--                    <span>Functions:</span>-->
<!--                    <span class="span" v-show="!isSelect7">Select some options.</span>-->
<!--                    <i v-show="isFunctions" class="location-war el-icon-warning"></i>-->
<!--                  </div>-->
<!--                  <div class="itemBox" v-show="isSelect7">-->
<!--                    <ul class="uli-Box" >-->
<!--                      <li v-for="(item, index) in items2" :key="index">-->
<!--                        {{ item }}-->
<!--                        <button @click="removeItem2(index)">X</button>-->
<!--                      </li>-->
<!--                    </ul>-->
<!--                  </div>-->
<!--                  <el-select v-model="select7" placeholder=" ">-->
<!--                    <el-option v-for="(item,index) in allItems2" :key="index" :label="item" :value="index"></el-option>-->
<!--                  </el-select>-->
<!--                </div>-->

                <div class="inputBox textarea">
                  <span>Area you reside in:</span>
                  <el-input
                      type="textarea"
                      :autosize="{ minRows: 6, maxRows: 6}"
                      placeholder="Please input Area you reside in."
                      v-model="selData.area_you_reside_in"
                      @input="getArea"
                  >
                  </el-input>
                  <i style="margin-top: 1%" class="el-icon-warning" v-show="isArea"></i>
                </div>
              </div>
            </div>
            <div class="col-12 item_N">
              <p>*Please do not upload from a cloud-based folder, download the document first and then upload.</p>
              <p>**Please attach a Microsoft Word (.doc or .docx) document or PDF file. CV Max file size: 4MB</p>
            </div>
            <div class="upload_box">
              <el-upload
                  class="upload-demo"
                  ref="upload"
                  action="https://jsonplaceholder.typicode.com/posts/"
                  :on-preview="handlePreview"
                  :on-remove="handleRemove"
                  :file-list="fileList"
                  :limit = "2"
                  :data = 'selData'
                  :auto-upload="false">
                <el-button slot="trigger" size="small" type="primary">Choose file</el-button>
                <span class="isFile">No file chosen</span>
                <i v-show="isChoose" class="choose_file education-war el-icon-warning"></i>
              </el-upload>
            </div>
            <div class="col-12 sel_box sel_box123">
              <div class="inputBox textarea">
                <span>Message</span>
                <el-input
                    type="textarea"
                    :autosize="{ minRows: 6, maxRows: 6}"
                    placeholder="Please input message."
                    v-model="selData.message"
                    @input="getMessage"
                >
                </el-input>
                <i style="margin-top: 1%" class="el-icon-warning" v-show="isMessage"></i>
              </div>
            </div>
            <div class="col-12 Submit_box">
              <button @click="submitUpload">Submit</button>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom_box">
        <p>
          We are the sole owners of the information collected on this site. We only collect and have access to information
          that you voluntarily give us via email or other direct contact from you. We will not sell or rent this information to anyone.
        </p>
        <p>
          We will use your information to respond to you, regarding the reason you contacted us. We will not share your information
          with any third party outside of our organisation, other than as necessary to full your request.
        </p>
      </div>
    </div>

    <div class="section5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-10 col-12 d-flex flex-column align-items-center">
            <h3>Here’s what happens after you submit your details</h3>
            <p>1. Your CV will be screened and should you meet all the requirements we will contact you for an interview.</p>
            <img class="mw-100" src="../../assets/images/vacancies/classify_img.png" alt="">
            <p>2. You will be asked to attend a Zoom or a face-to-face interview which lasts around 40 minutes.</p>
            <img class="mw-100" src="../../assets/images/vacancies/classify_img.png" alt="">
            <p>3. If your application is successful, you will be informed within 3 – 5 working days Mon- Fri and sent a job offer.</p>
            <img class="mw-100" src="../../assets/images/vacancies/classify_img.png" alt="">
            <p>4. After you have accepted your job offer, you will be sent over your official contract and visa guidance information and forms.</p>
            <img class="mw-100" src="../../assets/images/vacancies/classify_img.png" alt="">
            <div class="p_item">
              <p>5. Once we have received your signed contract and filled-in forms, we can begin your visa process.</p>
              <p>This can take between 6 – 12 weeks depending on which location you are traveling to.</p>
            </div>
            <img class="mw-100" src="../../assets/images/vacancies/classify_img.png" alt="">
            <p>6. Start getting excited for your next incredible adventure to Hong Kong or Macau!</p>
            <span>*The passport you hold can affect your eligibility for the visas needed for these locations. *</span>
          </div>
        </div>
      </div>
    </div>

    <Footer/>
  </div>
</template>

<script>
import Footer from "@/views/Layout/Footer.vue";
import store from "@/store";
import axios from "axios";
export default {
  components: {Footer},
  data() {
    return {
      fileList: [], // 上传文件
      activeName: 'first',
      allVacanciesData: [
        {
        name: '2025-02-19',
        date: 'Hong Kong',
      },
        {
        name: '2025-03-20',
        date: 'Hong Kong',
      },
        {
        name: '2025-04-22',
        date: 'Hong Kong',
      }],
      hkVacanciesData: [
        {
          date: '2025-02-19',
      },
        {
          date: '2025-03-20',
      },
        {
          date: '2025-04-22',
      }],
      selData:{
        last_name: '',
        surname: '',
        id_no: '',
        nationality: '',
        phone: '',
        tefl_certificate_held: '',
        passport_held:'',
        education_level:'',
        career_level:'',
        email: '',
        // sectors:[],
        // functions:'',
        message:'',
        marital_status:'',
        area_you_reside_in:'',
        region_you_reside_in: '',
        location_interested_in:'',
        type: false
      },
      input1: '',
      input2: '',
      input3: '',
      input4: '',
      input5: '',
      select6: '',
      select7: '',
      items1: [],
      items2: [],
      allItems1: ['select1', 'select2', 'select3', 'select4', 'select5', 'select6','select7'],
      allItems2: ['select1', 'select2', 'select3', 'select4', 'select5', 'select6'],
      allItems3: [' Entry level (up to 1 yrs exp.) ', ' Entry level (up to 2 yrs exp.) ', ' Entry level (up to 3 yrs exp.) ', ' Entry level (up to 4 yrs exp.) '],
      allItems4: [' Entry level (up to 11 yrs exp.) ', ' Entry level (up to 21 yrs exp.) ', ' Entry level (up to 31 yrs exp.) ', ' Entry level (up to 41 yrs exp.) '],
      isSelect6:false,
      isSelect7:false,
      isAll:true,
      isLast_name: false,
      isSurname: false,
      isId_no: false,
      isNationality:false,
      isPhones: false,
      isTefl: false,
      isPassport:false,
      isEducation:false,
      isCareer:false,
      isEmail: false,
      isSectors:false,
      isFunctions:false,
      isMessage:false,
      isChoose:false,
      isMarital:false,
      isArea:false,
      isRegion: false,
      isLocation:false,
      country: [
        { value: 'Angola', label: '安哥拉' },
        { value: 'Afghanistan', label: '阿富汗' },
        { value: 'Albania', label: '阿尔巴尼亚' },
        { value: 'Algeria', label: '阿尔及利亚' },
        { value: 'Andorra', label: '安道尔共和国' },
        { value: 'Anguilla', label: '安圭拉岛' },
        { value: 'Antigua and Barbuda', label: '安提瓜和巴布达' },
        { value: 'Argentina', label: '阿根廷' },
        { value: 'Armenia', label: '亚美尼亚' },
        { value: 'Ascension', label: '阿森松' },
        { value: 'Australia', label: '澳大利亚' },
        { value: 'Austria', label: '奥地利' },
        { value: 'Azerbaijan', label: '阿塞拜疆' },
        { value: 'Bahamas', label: '巴哈马' },
        { value: 'Bahrain', label: '巴林' },
        { value: 'Bangladesh', label: '孟加拉国' },
        { value: 'Barbados', label: '巴巴多斯' },
        { value: 'Belarus', label: '白俄罗斯' },
        { value: 'Belgium', label: '比利时' },
        { value: 'Belize', label: '伯利兹' },
        { value: 'Benin', label: '贝宁' },
        { value: 'Bermuda Is', label: '百慕大群岛' },
        { value: 'Bolivia', label: '玻利维亚' },
        { value: 'Botswana', label: '博茨瓦纳' },
        { value: 'Brazil', label: '巴西' },
        { value: 'Brunei', label: '文莱' },
        { value: 'Bulgaria', label: '保加利亚' },
        { value: 'Burkina Faso', label: '布基纳法索' },
        { value: 'Burma', label: '缅甸' },
        { value: 'Burundi', label: '布隆迪' },
        { value: 'Cameroon', label: '喀麦隆' },
        { value: 'Canada', label: '加拿大' },
        { value: 'Cayman Is', label: '开曼群岛' },
        { value: 'Central African Republic', label: '中非共和国' },
        { value: 'Chad', label: '乍得' },
        { value: 'Chile', label: '智利' },
        { value: 'China', label: '中国' },
        { value: 'Colombia', label: '哥伦比亚' },
        { value: 'Congo', label: '刚果' },
        { value: 'Cook Is', label: '库克群岛' },
        { value: 'Costa Rica', label: '哥斯达黎加' },
        { value: 'Cuba', label: '古巴' },
        { value: 'Cyprus', label: '塞浦路斯' },
        { value: 'Czech Republic', label: '捷克' },
        { value: 'Denmark', label: '丹麦' },
        { value: 'Djibouti', label: '吉布提' },
        { value: 'Dominica Rep', label: '多米尼加共和国' },
        { value: 'Ecuador', label: '厄瓜多尔' },
        { value: 'Egypt', label: '埃及' },
        { value: 'EI Salvador', label: '萨尔瓦多' },
        { value: 'Estonia', label: '爱沙尼亚' },
        { value: 'Ethiopia', label: '埃塞俄比亚' },
        { value: 'Fiji', label: '斐济' },
        { value: 'Finland', label: '芬兰' },
        { value: 'France', label: '法国' },
        { value: 'French Guiana', label: '法属圭亚那' },
        { value: 'French Polynesia', label: '法属玻利尼西亚' },
        { value: 'Gabon', label: '加蓬' },
        { value: 'Gambia', label: '冈比亚' },
        { value: 'Georgia', label: '格鲁吉亚' },
        { value: 'Germany', label: '德国' },
        { value: 'Ghana', label: '加纳' },
        { value: 'Gibraltar', label: '直布罗陀' },
        { value: 'Greece', label: '希腊' },
        { value: 'Grenada', label: '格林纳达' },
        { value: 'Guam', label: '关岛' },
        { value: 'Guatemala', label: '危地马拉' },
        { value: 'Guinea', label: '几内亚' },
        { value: 'Guyana', label: '圭亚那' },
        { value: 'Haiti', label: '海地' },
        { value: 'Honduras', label: '洪都拉斯' },
        { value: 'Hungary', label: '匈牙利' },
        { value: 'Iceland', label: '冰岛' },
        { value: 'India', label: '印度' },
        { value: 'Indonesia', label: '印度尼西亚' },
        { value: 'Iran', label: '伊朗' },
        { value: 'Iraq', label: '伊拉克' },
        { value: 'Ireland', label: '爱尔兰' },
        { value: 'Israel', label: '以色列' },
        { value: 'Italy', label: '意大利' },
        { value: 'Ivory Coast', label: '科特迪瓦' },
        { value: 'Jamaica', label: '牙买加' },
        { value: 'Japan', label: '日本' },
        { value: 'Jordan', label: '约旦' },
        { value: 'Kampuchea (Cambodia )', label: '柬埔寨' },
        { value: 'Kazakstan', label: '哈萨克斯坦' },
        { value: 'Kenya', label: '肯尼亚' },
        { value: 'Korea', label: '韩国' },
        { value: 'Kuwait', label: '科威特' },
        { value: 'Kyrgyzstan', label: '吉尔吉斯坦' },
        { value: 'Laos', label: '老挝' },
        { value: 'Latvia', label: '拉脱维亚' },
        { value: 'Lebanon', label: '黎巴嫩' },
        { value: 'Lesotho', label: '莱索托' },
        { value: 'Liberia', label: '利比里亚' },
        { value: 'Libya', label: '利比亚' },
        { value: 'Liechtenstein', label: '列支敦士登' },
        { value: 'Lithuania', label: '立陶宛' },
        { value: 'Luxembourg', label: '卢森堡' },
        { value: 'Madagascar', label: '马达加斯加' },
        { value: 'Malawi', label: '马拉维' },
        { value: 'Malaysia', label: '马来西亚' },
        { value: 'Maldives', label: '马尔代夫' },
        { value: 'Mali', label: '马里' },
        { value: 'Malta', label: '马耳他' },
        { value: 'Mariana Is', label: '马里亚那群岛' },
        { value: 'Martinique', label: '马提尼克' },
        { value: 'Mauritius', label: '毛里求斯' },
        { value: 'Mexico', label: '墨西哥' },
        { value: 'Moldova', label: '摩尔多瓦' },
        { value: 'Monaco', label: '摩纳哥' },
        { value: 'Mongolia', label: '蒙古' },
        { value: 'Montserrat Is', label: '蒙特塞拉特岛' },
        { value: 'Morocco', label: '摩洛哥' },
        { value: 'Mozambique', label: '莫桑比克' },
        { value: 'Namibia', label: '纳米比亚' },
        { value: 'Nauru', label: '瑙鲁' },
        { value: 'Nepal', label: '尼泊尔' },
        { value: 'Netheriands Antilles', label: '荷属安的列斯' },
        { value: 'Netherlands', label: '荷兰' },
        { value: 'New Zealand', label: '新西兰' },
        { value: 'Nicaragua', label: '尼加拉瓜' },
        { value: 'Niger', label: '尼日尔' },
        { value: 'Nigeria', label: '尼日利亚' },
        { value: 'North Korea', label: '朝鲜' },
        { value: 'Norway', label: '挪威' },
        { value: 'Oman', label: '阿曼' },
        { value: 'Pakistan', label: '巴基斯坦' },
        { value: 'Panama', label: '巴拿马' },
        { value: 'Papua New Cuinea', label: '巴布亚新几内亚' },
        { value: 'Paraguay', label: '巴拉圭' },
        { value: 'Peru', label: '秘鲁' },
        { value: 'Philippines', label: '菲律宾' },
        { value: 'Poland', label: '波兰' },
        { value: 'Portugal', label: '葡萄牙' },
        { value: 'Puerto Rico', label: '波多黎各' },
        { value: 'Qatar', label: '卡塔尔' },
        { value: 'Reunion', label: '留尼旺' },
        { value: 'Romania', label: '罗马尼亚' },
        { value: 'Russia', label: '俄罗斯' },
        { value: 'Saint Lueia', label: '圣卢西亚' },
        { value: 'Saint Vincent', label: '圣文森特岛' },
        { value: 'Samoa Eastern', label: '东萨摩亚(美)' },
        { value: 'Samoa Western', label: '西萨摩亚' },
        { value: 'San Marino', label: '圣马力诺' },
        { value: 'Sao Tome and Principe', label: '圣多美和普林西比' },
        { value: 'Saudi Arabia', label: '沙特阿拉伯' },
        { value: 'Senegal', label: '塞内加尔' },
        { value: 'Seychelles', label: '塞舌尔' },
        { value: 'Sierra Leone', label: '塞拉利昂' },
        { value: 'Singapore', label: '新加坡' },
        { value: 'Slovakia', label: '斯洛伐克' },
        { value: 'Slovenia', label: '斯洛文尼亚' },
        { value: 'Solomon Is', label: '所罗门群岛' },
        { value: 'Somali', label: '索马里' },
        { value: 'South Africa', label: '南非' },
        { value: 'Spain', label: '西班牙' },
        { value: 'SriLanka', label: '斯里兰卡' },
        { value: 'St.Lucia', label: '圣卢西亚' },
        { value: 'St.Vincent', label: '圣文森特' },
        { value: 'Sudan', label: '苏丹' },
        { value: 'Suriname', label: '苏里南' },
        { value: 'Swaziland', label: '斯威士兰' },
        { value: 'Sweden', label: '瑞典' },
        { value: 'Switzerland', label: '瑞士' },
        { value: 'Syria', label: '叙利亚' },
        { value: 'Tajikstan', label: '塔吉克斯坦' },
        { value: 'Tanzania', label: '坦桑尼亚' },
        { value: 'Thailand', label: '泰国' },
        { value: 'Togo', label: '多哥' },
        { value: 'Tonga', label: '汤加' },
        { value: 'Trinidad and Tobago', label: '特立尼达和多巴哥' },
        { value: 'Tunisia', label: '突尼斯' },
        { value: 'Turkey', label: '土耳其' },
        { value: 'Turkmenistan', label: '土库曼斯坦' },
        { value: 'Uganda', label: '乌干达' },
        { value: 'Ukraine', label: '乌克兰' },
        { value: 'United Arab Emirates', label: '阿拉伯联合酋长国' },
        { value: 'United Kiongdom', label: '英国' },
        { value: 'United States of America', label: '美国' },
        { value: 'Uruguay', label: '乌拉圭' },
        { value: 'Uzbekistan', label: '乌兹别克斯坦' },
        { value: 'Venezuela', label: '委内瑞拉' },
        { value: 'Vietnam', label: '越南' },
        { value: 'Yemen', label: '也门' },
        { value: 'Yugoslavia', label: '南斯拉夫' },
        { value: 'Zimbabwe', label: '津巴布韦' },
        { value: 'Zaire', label: '扎伊尔' },
        { value: 'Zambia', label: '赞比亚' }
      ],
    }
  },
  watch: {
    select6(i,e) {
      // console.log("select6==>",i)
      if(this.items1.indexOf(this.allItems1[i]) ===-1){
        this.items1.push(this.allItems1[i])
      }
    },
    select7(i,e) {
      if(this.items2.indexOf(this.allItems2[i]) ===-1){
        this.items2.push(this.allItems2[i])
      }
    },
    items1(i,e){
      if(e.length === 0){
        this.isSelect6 = false;
        this.selData.type = false;
        this.isSectors = true;
      }else {
        this.isSelect6 = true;
        this.isSectors = false;
        this.selData.type = true;
        this.selData.sectors = e;
      }
   },
    items2(i,e){
      if(e.length === 0){
        this.isSelect7 = false;
        this.selData.type = false;
        this.isFunctions = true;
      }else {
        this.isSelect7 = true;
        this.selData.type = true;
        this.isFunctions = false;
        this.selData.functions = e;

      }
    },
  },
  created() {
    this.getCountry()
  },
  mounted() {

  },
  methods: {
    geToContact() {
      this.$router.push('/ContactUs')
    },
    handleClick(tab, event) {
      // console.log(tab.name);
      if(tab.name === "first"){
        this.isAll = true;
      }else {
        this.isAll = false;
      }
    },
    removeItem1(index) {
      this.items1.splice(index, 1);
    },
    removeItem2(index) {
      this.items2.splice(index, 1);
    },
    submitUpload() {
      this.$refs.upload.submit();
      // console.log(this.$refs.upload.submit())
      let regContact = new RegExp("^[0-9]*$");
      let regEmail = /.+@.+\.[a-zA-Z]{2,4}$/;
      if(this.selData.last_name === ''){this.isLast_name = true; this.selData.type = false;}
      if(this.selData.surname === ''){this.isSurname = true; this.selData.type = false;}
      if(this.selData.phone === '' || !regContact.test(this.selData.phone)){this.isPhones = true; this.selData.type = false;}
      if(this.selData.email === '' || !regEmail.test(this.selData.email)){this.isEmail = true; this.selData.type = false;}
      if(this.selData.id_no === ''){this.isId_no = true; this.selData.type = false;}
      if(this.selData.nationality === ''){this.isNationality = true; this.selData.type = false;}
      if(this.selData.marital_status === ''){this.isMarital = true; this.selData.type = false;}
      if(this.selData.education_level === ''){this.isEducation = true; this.selData.type = false;}
      if(this.selData.tefl_certificate_held === ''){this.isTefl = true; this.selData.type = false;}
      if(this.selData.passport_held === ''){this.isPassport = true; this.selData.type = false;}
      if(this.selData.career_level === ''){this.isCareer = true; this.selData.type = false;}
      if(this.selData.location_interested_in === ''){this.isLocation = true; this.selData.type = false;}
      // if(this.selData.sectors.length === 0 ){this.isSectors = true; this.selData.type = false;}
      // if(this.selData.functions === ''){this.isFunctions = true; this.selData.type = false;}
      if(this.selData.region_you_reside_in === ''){this.isRegion = true; this.selData.type = false;}
      if(this.selData.area_you_reside_in === ''){this.isArea = true; this.selData.type = false;}
      if(this.selData.message === ''){this.isMessage = true; this.selData.type = false;}

      if(this.selData.type){
        this.$confirm('Submission Successful !', '', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          // type: 'warning',// 图标
          center: true
        })
        console.log("发送成功！",this.selData)

      }else {
        console.log("发送失败！",this.selData)
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file, fileList) {
      console.log(file,fileList);
    },

    getLastName(event) {
      if(this.selData.last_name === '') {
        this.isLast_name = true;
        this.selData.type = false;
      }else {
        this.isLast_name = false;
        this.selData.last_name = event;
        this.selData.type = true;
      }
    },
    getNationality(event){
      if(this.selData.nationality === '') {
        this.isNationality = true;
        this.selData.type = false;
      }else {
        this.isNationality = false;
        this.selData.nationality = event;
        this.selData.type = true;
      }
    },
    getPhone(event){
      let regContact = new RegExp("^[0-9]*$");
      if (event === "" || (event !== "" && !regContact.test(event))) {
        this.isPhones = true;
        this.selData.type = false;
      }
      else {
        this.isPhones = false;
        this.selData.phone = event;
        this.selData.type = true;

      }
    },
    getMarital(event){
      if(this.selData.marital_status === '') {
        this.isMarital = true;
        this.selData.type = false;
      }else {
        this.isMarital = false;
        this.selData.marital_status = event;
        this.selData.type = true;
      }
    },
    getTefl(event){
      if(this.selData.tefl_certificate_held === '') {
        this.isTefl = true;
        this.selData.type = false;
      }else {
        this.isTefl = false;
        this.selData.tefl_certificate_held = event;
        this.selData.type = true;
      }
    },
    getCareer(event){
      if(this.selData.career_level === '') {
        this.isCareer = true;
        this.selData.type = false;
      }else {
        this.isCareer = false;
        this.selData.career_level = event;
        this.selData.type = true;
      }
    },
    getRegion(event){
      if(this.selData.region_you_reside_in === '') {
        this.isRegion = true;
        this.selData.type = false;
      }else {
        this.isRegion = false;
        this.selData.region_you_reside_in = event;
        this.selData.type = true;
      }
    },
    getSurname(event) {
      if(this.selData.surname === '') {
        this.isSurname = true;
        this.selData.type = false;
      }else {
        this.isSurname = false;
        this.selData.surname = event;
        this.selData.type = true;
      }
    },
    getIDno(event){
      if (this.selData.id_no === "") {
        this.isId_no = true;
        this.selData.type = false;
      }
      else {
        this.isId_no = false;
        this.selData.id_no = event;
        this.selData.type = true;
      }
    },
    getEmail(event){
      let regEmail = /.+@.+\.[a-zA-Z]{2,4}$/;
      if(event === "" || (event !== "" && !regEmail.test(event))){
        this.isEmail = true;
        this.selData.type = false;
      }
      else{
        this.isEmail = false;
        this.selData.email = event;
        this.selData.type = true;
      }
    },
    getEducation(event) {
      if(this.selData.education_level === '') {
        this.isEducation = true;
        this.selData.type = false;
      }else {
        this.isEducation = false;
        this.selData.education_level = event;
        this.selData.type = true;
      }
    },
    getPassport(event){
      if(this.selData.passport_held === "") {
        this.isPassport = true;
        this.selData.type = false;
      }
      else {
        this.isPassport = false;
        this.selData.passport_held = event;
        this.selData.type = true;
      }
    },
    getLocation(event) {
      if(this.selData.location_interested_in === "") {
        this.isLocation = true;
        this.selData.type = false;
      }
      else {
        this.isLocation = false;
        this.selData.location_interested_in = event;
        this.selData.type = true;
      }
    },
    getArea(event){
      if(this.selData.area_you_reside_in === "") {
        this.isArea = true;
        this.selData.type = false;
      }
      else {
        this.isArea = false;
        this.selData.area_you_reside_in = event;
        this.selData.type = true;
      }
    },
    getMessage(event){
      if(this.selData.message === "") {
        this.isMessage = true;
        this.selData.type = false;
      }
      else {
        this.isMessage = false;
        this.selData.message = event;
        this.selData.type = true;
      }
    },
    getCountry() {
      this.$api.getContact({})
      .then(res=>{
        console.log("成功获取==》",res)

      }).catch(err=>{
        console.log("失败获取==》",err)
      })
    }
  }
}
</script>

<style>
.el-tabs__nav-wrap::after {
  background-color: transparent!important;
}
.el-tabs__item:hover {
  color: #DA191E !important;
}
.el-tabs__item.is-active {
  color: #DA191E !important;
}
.el-tabs__active-bar {
  background-color: #DA191E !important;
}
.el-popper[x-placement^=bottom] {
  margin-top: -10px !important;
}
.el-input__suffix-inner {
  right: 0%;
}
.el-select-dropdown {
  border: 1px solid transparent !important;
  box-shadow: 0 0px 0px 0 rgba(0,0,0,0)!important;
}

@media (max-width: 1290px) {
  .el-select-dropdown__item:last-child {
    margin-bottom: 5%;
  }

}@media (max-width: 1190px) {
  .el-select-dropdown__item {
    font-size: 11px !important;
  }
  .el-input {
    font-size: 12px !important;
  }
}

@media (max-width: 991px) {
  .el-popper[x-placement^=bottom] {
    margin-top: -7px !important;
  }
  .el-select-dropdown__item:last-child {
    margin-bottom: 5%;
  }
}

@media (max-width: 678px) {
  .el-popper[x-placement^=bottom] {
    margin-top: -7px !important;
  }
  .el-select-dropdown__item:last-child {
    margin-bottom: 5%;
  }
  .el-input__icon {
    line-height: 32px !important;
  }
  .el-upload-list__item {
    font-size: 9px !important;
  }
  .el-upload-list__item-name {
    margin-right: 25px !important;
  }
  .el-select-dropdown__item {
    font-size: 9px !important;
  }
}

@media (max-width: 468px) {
  .el-tabs__item {
    height: 33px !important;
    line-height: 33px !important;
  }
  .el-table .el-table__cell {
    padding: 0 !important;
  }
  .el-select-dropdown__item {
    font-size: 8px !important;
  }
  .el-upload-list__item {
    font-size: 8px !important;
  }
}

@media (max-width: 425px) {
  .el-upload-list__item-name {
    margin-right: 17px !important;
  }
}

@media (max-width: 390px) {
  .el-upload-list__item {
    font-size: 7px !important;
  }
}

@media (max-width: 375px) {
  .el-tabs__item {
    height: 26px !important;
    line-height: 28px !important;
  }
  .el-input__icon {
    line-height: 27px !important;
  }
  .el-popper[x-placement^=bottom] {
    margin-top: 0px !important;
  }
  .el-input__icon {
    width: 15px !important;
  }
  .el-select-dropdown__item {
    height: 20px;
    line-height: 20px;
  }
}

@media (max-width: 366px) {
  .el-select-dropdown__item {
    font-size: 8px !important;
    height: 20px !important;
    line-height: 20px !important;
  }
  .el-input__icon {
    line-height: 25px !important;
  }
}

@media (max-width: 320px){
  .el-input__icon {
    line-height: 22px !important;
  }
}


</style>