<template>
  <div class="Reviews">
    <div class="container">
      <div class="row">
        <div class="section1">
          <div class="col-lg-6 col-12">
            <div class="left_box">
              <div class="itemLeft">
                <h1 class="">Our Teachers</h1>
                <div class="hr"></div>
                <p>
                  Teaching abroad is not only kind to your bank balance, but also allows you to travel and live new experiences!
                  Don’t take our word for it – have a look at the testimonials from some of our teachers!
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="section2 d-flex flex-wrap">
          <div class="col-lg-6 col-12 imgBox" @mouseenter="handleMouseOver1( )" @mouseleave="handleMouseOut1( )">
            <img class="mw-100" :src="imgSrc[0].res1" alt="" v-show="imgSrc[0].isSrc1">
            <img class="mw-100" :src="imgSrc[0].res2" alt="" v-show="!imgSrc[0].isSrc1" >
            <div class="itemBox">
              <h3>{{imgSrc[0].name}}</h3>
              <p>{{imgSrc[0].content}}</p>
            </div>
          </div>
          <div class="col-lg-6 col-12 imgBox" @mouseenter="handleMouseOver2( )" @mouseleave="handleMouseOut2( )">
            <img class="mw-100" :src="imgSrc[1].res1" alt="" v-show="imgSrc[1].isSrc2">
            <img class="mw-100" :src="imgSrc[1].res2" alt="" v-show="!imgSrc[1].isSrc2" >
            <div class="itemBox">
              <h3>{{imgSrc[1].name}}</h3>
              <p>{{imgSrc[1].content}}</p>
            </div>
          </div>
          <div class="col-lg-6 col-12 imgBox" @mouseenter="handleMouseOver3( )" @mouseleave="handleMouseOut3( )">
            <img class="mw-100" :src="imgSrc[2].res1" alt="" v-show="imgSrc[2].isSrc3">
            <img class="mw-100" :src="imgSrc[2].res2" alt="" v-show="!imgSrc[2].isSrc3" >
            <div class="itemBox">
              <h3>{{imgSrc[2].name}}</h3>
              <p>{{imgSrc[2].content}}</p>
            </div>
          </div>
          <div class="col-lg-6 col-12 imgBox" @mouseenter="handleMouseOver4( )" @mouseleave="handleMouseOut4( )">
            <img class="mw-100" :src="imgSrc[3].res1" alt="" v-show="imgSrc[3].isSrc4">
            <img class="mw-100" :src="imgSrc[3].res2" alt="" v-show="!imgSrc[3].isSrc4" >
            <div class="itemBox">
              <h3>{{imgSrc[3].name}}</h3>
              <p>{{imgSrc[3].content}}</p>
            </div>
          </div>
          <div class="col-lg-6 col-12 imgBox" @mouseenter="handleMouseOver5( )" @mouseleave="handleMouseOut5( )">
            <img class="mw-100" :src="imgSrc[4].res1" alt="" v-show="imgSrc[4].isSrc5">
            <img class="mw-100" :src="imgSrc[4].res2" alt="" v-show="!imgSrc[4].isSrc5" >
            <div class="itemBox">
              <h3>{{imgSrc[4].name}}</h3>
              <p>{{imgSrc[4].content}}</p>
            </div>
          </div>
          <div class="col-lg-6 col-12 imgBox" @mouseenter="handleMouseOver6( )" @mouseleave="handleMouseOut6( )">
            <img class="mw-100" :src="imgSrc[5].res1" alt="" v-show="imgSrc[5].isSrc6">
            <img class="mw-100" :src="imgSrc[5].res2" alt="" v-show="!imgSrc[5].isSrc6" >
            <div class="itemBox">
              <h3>{{imgSrc[5].name}}</h3>
              <p>{{imgSrc[5].content}}</p>
            </div>
          </div>
          <div class="col-lg-6 col-12 imgBox" v-show="isMore" @mouseenter="handleMouseOver7( )" @mouseleave="handleMouseOut7( )">
            <img class="mw-100" :src="imgSrc[6].res1" alt="" v-show="imgSrc[6].isSrc7">
            <img class="mw-100" :src="imgSrc[6].res2" alt="" v-show="!imgSrc[6].isSrc7" >
            <div class="itemBox">
              <h3>{{imgSrc[6].name}}</h3>
              <p>{{imgSrc[6].content}}</p>
            </div>
          </div>
          <div class="col-lg-6 col-12 imgBox" v-show="isMore" @mouseenter="handleMouseOver8( )" @mouseleave="handleMouseOut8( )">
            <img class="mw-100" :src="imgSrc[7].res1" alt="" v-show="imgSrc[7].isSrc8">
            <img class="mw-100" :src="imgSrc[7].res2" alt="" v-show="!imgSrc[7].isSrc8" >
            <div class="itemBox">
              <h3>{{imgSrc[7].name}}</h3>
              <p>{{imgSrc[7].content}}</p>
            </div>
          </div>
          <div class="col-12 btnBox" v-show="!this.isMore">
            <button @click="getMore">To View More</button>
          </div>
        </div>
      </div>
    </div>
    <div class="section3" v-show="isMore">
      <div class="imgBox">
        <img class="mw-100 d-lg-block d-none" src="../../assets/images/reviews/GTR-slogan-long.png" alt="">
        <img class="mw-100 d-lg-none d-block" src="../../assets/images/reviews/GTR-slogan-short.png" alt="">
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Footer from "@/views/Layout/Footer.vue";
export default {
  components: {Footer},
  data() {
    return {
         isMore:false,
         imgSrc:[
             {
               res1:require("../../assets/images/reviews/GTR-review-1.png"),
               res2:require("../../assets/images/reviews/GTR-review-bw1.png"),
               isSrc1:true,
               name:'Ms. Thaania',
               content:
                   "is from Cape Town, South Africa. She obtained her Bachelor's degree majoring in Psychology " +
                   "and Linguistics at The University of the Western Cape and completed her TEFL soon after. " +
                   "She decided to move to Hong Kong to experience the culture and food as she loves trying new and different cuisine. " +
                   "In her free time, she enjoys travelling and visiting different restaurants all over Hong Kong."
             },
           {
             res1:require("../../assets/images/reviews/GTR-review-2.png"),
             res2:require("../../assets/images/reviews/GTR-review-bw2.png"),
             isSrc2:true,
             name:'Mr. James',
             content:
                 "graduated from UKZN in South Africa with a honours degree in Psychology, achieved in 2017. He has been a manager " +
                 "for the last three years. Mr. James is a people’s person who draws inspiration through interaction with others. " +
                 "Driven to achieve, he hopes to inspire a future generation to excel and reach their maximum potential."
           },
           {
             res1:require("../../assets/images/reviews/GTR-review-8.png"),
             res2:require("../../assets/images/reviews/GTR-review-bw8.png"),
             isSrc3:true,
             name:'Ms. Bianca',
             content:
                 "is a South African from a small seaside town called Knysna. She enjoys creative activities and loves helping people, " +
                 "this led to her wanting to help children express themselves in English and other creative ways."
           },
           {
             res1:require("../../assets/images/reviews/GTR-review-7.png"),
             res2:require("../../assets/images/reviews/GTR-review-bw7.png"),
             isSrc4:true,
             name:'Mr. Lou',
             content:
                 "is originally from Dundee, Scotland where he studied Textile Design. After getting his degree he volunteered at " +
                 "local charities and youth centers which involved taking children out on daytrips and getting them involved in art projects. " +
                 "Mr. Lou is very excited to be in Hong Kong where he can help his students improve upon their English skills."
           },
           {
             res1:require("../../assets/images/reviews/GTR-review-6.png"),
             res2:require("../../assets/images/reviews/GTR-review-bw6.png"),
             isSrc5:true,
             name:'Ms. Leandi',
             content:
                 "is a teacher from South Africa who loves animals and learning about the people around her. " +
                 "She has a degree in English Creative Writing and is passionate about exploring the world and pursuing knowledge. " +
                 "She admires the beauty of nature just as much as she enjoys losing herself in the pages of a good book."
           },
           {
             res1:require("../../assets/images/reviews/GTR-review-5.png"),
             res2:require("../../assets/images/reviews/GTR-review-bw5.png"),
             isSrc6:true,
             name:'Mr. Yohanan',
             content:
                 "obtained his degree in Secondary English Education and had been a shadow teacher at a school, " +
                 "he decided to join Monkey Tree so that he can expand his knowledge through his passion for educating young children. " +
                 "In his free time, he enjoys hiking with friends."
           },
           {
             res1:require("../../assets/images/reviews/GTR-review-4.png"),
             res2:require("../../assets/images/reviews/GTR-review-bw4.png"),
             isSrc7:true,
             name:'Mrs. Savanna',
             content:
                 "is from Cape Town, South Africa. She studied a Bachelor’s degree in Foundation Phase Education and has a TEFL certificate. " +
                 "She has experience working with children at a primary school and being a private tutor. She loves being outdoors, " +
                 "traveling and playing sports. She wants to inspire children to love learning."
           },
           {
             res1:require("../../assets/images/reviews/GTR-review-3.png"),
             res2:require("../../assets/images/reviews/GTR-review-bw3.png"),
             isSrc8:true,
             name:'Mr. Thomas',
             content:
                 "is a Native English Teacher from the United Kingdom. He studied political science and Western philosophy in Canada before pursuing " +
                 "a career in teaching. He believes teaching children is a privilege and strives to deliver the best education possible " +
                 "to the next generation of Hong Kong. In his free time, he enjoys playing basketball, gardening and reading."
           },
         ],
      }
  },
  methods: {
    getMore() {
      this.isMore = true;
    },
    handleMouseOver1( ) {
      this.imgSrc[0].isSrc1 = false;
    },
    handleMouseOut1( ) {
      this.imgSrc[0].isSrc1 = true;
    },
    handleMouseOver2( ) {
      this.imgSrc[1].isSrc2 = false;
    },
    handleMouseOut2( ) {
      this.imgSrc[1].isSrc2 = true;
    },
    handleMouseOver3( ) {
      this.imgSrc[2].isSrc3 = false;
    },
    handleMouseOut3( ) {
      this.imgSrc[2].isSrc3 = true;
    },
    handleMouseOver4( ) {
      this.imgSrc[3].isSrc4 = false;
    },
    handleMouseOut4( ) {
      this.imgSrc[3].isSrc4 = true;
    },
    handleMouseOver5( ) {
      this.imgSrc[4].isSrc5 = false;
    },
    handleMouseOut5( ) {
      this.imgSrc[4].isSrc5 = true;
    },
    handleMouseOver6( ) {
      this.imgSrc[5].isSrc6 = false;
    },
    handleMouseOut6( ) {
      this.imgSrc[5].isSrc6 = true;
    },
    handleMouseOver7( ) {
      this.imgSrc[6].isSrc7 = false;
    },
    handleMouseOut7( ) {
      this.imgSrc[6].isSrc7 = true;
    },
    handleMouseOver8( ) {
      this.imgSrc[7].isSrc8 = false;
    },
    handleMouseOut8( ) {
      this.imgSrc[7].isSrc8 = true;
    },
  }
}
</script>

<style scoped>

</style>