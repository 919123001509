<template>
<!--  <div class="OurServices">-->
<!--    <div class="container">-->
<!--      <div class="row">-->
<!--        <div class="col-12" style="height: 2000px">-->
<!--          OurServices-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <Footer/>-->
<!--  </div>-->
  <div id="OurServices">
    <router-view/>
  </div>

</template>

<script>
import Footer from "@/views/Layout/Footer.vue";
import OurServices_FYS from "@/views/OurServices/OurServices_FYS.vue";
import OurServices_TA from "@/views/OurServices/OurServices_TA.vue";
export default {
  name: "OurServices",
  components: {
    OurServices_FYS,
    OurServices_TA
  },
};
</script>

<style scoped>

</style>