<template>
<!--  <div class="home">-->
<!--    <button @click="show = true">点我滚入</button>-->
<!--    <button @click="show = false">点我滚出</button>-->
<!--    <transition-->
<!--        enter-active-class="rollIn1"-->
<!--        leave-active-class="rollOut"-->
<!--    >-->
<!--      <div v-show="show" class="circle200">滚动动画</div>-->
<!--    </transition>-->
<!--    <div class="rollIn1">-->
<!--      13456 456 7489 46546-->
<!--    </div>-->
<!--  </div>-->
  <div class="home">

    <div class="BigImg-box">
      <div class="blackBox"></div>
      <img class="mw-100 header-img d-lg-block d-none" src="../../assets/images/smiling-happy-young.jpg" alt="">
      <img class="mw-100 header-img d-lg-none d-block" src="../../assets/images/smiling-happy-small.jpg" alt="">
      <div class="itemBox" :class="isItem1 ? 'rollIn1' : ''" v-show="isItem1">
        <h1 >Quality Teacher</h1>
        <h1 class="">Recruitment Services</h1>
        <p>Teach Abroad / Hire For Your School</p>
        <a @click="toContact" class="left-btn">
          <button>Contact Us</button>
        </a>
      </div>
    </div>

    <div class="section1" id="isItem2">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <img class="mw-100" src="../../assets/images/Asset 21.png" alt="">
            <div class="itemBox">
              <div class="container">
                <div class="row">
                  <div class="col-lg-6 col-12 itemH" :class="isItem2 ? 'rollIn2' : ''" v-show="isItem2">
                    <h1 class="">Quality Teacher</h1>
                    <h1 class="">Recruitment</h1>
                    <h5>The foundation and focus of</h5>
                    <h5>our business, and promise to you.</h5>
                  </div>
                  <div class="col-lg-6 col-12 itemP" :class="isItem3 ? 'rollIn3' : ''" v-show="isItem3">
                    <div class="hr"></div>
                    <p>
                      As a prospective candidate, our specialized recruitment services have been designed
                      to ensure that your transition to teaching abroad is as easy and as stress-free as possible.
                      lf your school or learning centre are looking to hire teachers, we have designed systems and
                      procedures that have a keen focus on finding the right teacher that meet your desired requirements.
                    </p>
                    <a @click="toContact" class="left-btn">
                      <button>Contact Us</button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section2 d-lg-block d-none" id="isItem3">
      <div class="BottomBox d-lg-block d-none"></div>
      <div class="itemBox">
        <div class="container">
          <div class="row item-box">
            <div class="col-lg-5 col-12 itemL" :class="isItem4 ? 'rollIn4' : ''" v-show="isItem4">
              <img class="mw-100 d-lg-block d-none" src="../../assets/images/Asset 22.png" alt="">
              <img class="mw-100 d-lg-none d-block" src="../../assets/images/Asset 41.png" alt="">
              <div class="col-lg-6 col-12 itemH">
                <h3 class="">Connect With Us As A</h3>
                <h3 class="">Prospective Teacher</h3>
                <h3 class="" style="opacity: 0">Prospective Teacher</h3>
              </div>
              <div class="col-lg-6 col-12 itemP">
                <div class="hr"></div>
                <p>
                  We have multiple vacancies on offer, primarily in Hong Kong, Macau, and other
                  parts of Southeast Asia. Global Teacher Recruitment is always expanding and
                  identifying new and legitimate opportunities that could be of interest to you!
                </p>
                <a @click="toContact" class="left-btn">
                  <button>Contact Us</button>
                </a>
              </div>
            </div>
            <div class="col-lg-5 col-12 itemL" :class="isItem5 ? 'rollIn5' : ''" v-show="isItem5">
              <img class="mw-100 d-lg-block d-none" src="../../assets/images/Asset 23.png" alt="">
              <img class="mw-100 d-lg-none d-block" src="../../assets/images/Asset 42.png" alt="">
              <div class="col-lg-6 col-12 itemH">
                <h3 class="">We Can Help Supply</h3>
                <h3 class=""> Your School With</h3>
                <h3 class="">Quality Teachers</h3>
              </div>
              <div class="col-lg-6 col-12 itemP">
                <div class="hr"></div>
                <p>
                  Global Teacher Recruitment's impeccable track record in hiring
                  quality candidates from across the globe will ensure your
                  school is provided with teachers that meet your high standards.
                  <span class="d-lg-block d-none" style="opacity: 0">opportunities that could be of interest to you!</span>
                </p>
                <a @click="toForYourSchool" class="left-btn">
                  <button>Read More</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section2" id="isItem4">
      <div class="BottomBox" style="opacity: 1;height:50px;background:#fff" v-show="!isItem4"></div>
      <div class="itemBox">
        <div class=" item-box">
          <div class="itemL" :class="isItem4 ? 'rollIn4' : ''" v-show="isItem4">
            <img class="mw-100 d-lg-block d-none" src="../../assets/images/Asset 22.png" alt="">
            <img class="mw-100 d-lg-none d-block" src="../../assets/images/Asset 41.png" alt="">
            <div class="itemH">
              <h3 class="">Connect With Us As A</h3>
              <h3 class="">Prospective Teacher</h3>
              <h3 class="" style="opacity: 0">Prospective Teacher</h3>
            </div>
            <div class="itemP">
              <div class="hr"></div>
              <p>
                We have multiple vacancies on offer, primarily in Hong Kong, Macau, and other
                parts of Southeast Asia. Global Teacher Recruitment is always expanding and
                identifying new and legitimate opportunities that could be of interest to you!
              </p>
              <a @click="toContact" class="left-btn">
                <button>Contact Us</button>
              </a>
            </div>
          </div>
          <div class="col-lg-5 col-12 itemL" :class="isItem5 ? 'rollIn5' : ''" v-show="isItem5">
            <img class="mw-100 d-lg-block d-none" src="../../assets/images/Asset 23.png" alt="">
            <img class="mw-100 d-lg-none d-block" src="../../assets/images/Asset 42.png" alt="">
            <div class="itemH">
              <h3 class="">We Can Help Supply</h3>
              <h3 class=""> Your School With</h3>
              <h3 class="">Quality Teachers</h3>
            </div>
            <div class="itemP">
              <div class="hr"></div>
              <p>
                Global Teacher Recruitment's impeccable track record in hiring
                quality candidates from across the globe will ensure your
                school is provided with teachers that meet your high standards.
                <span class="d-lg-block d-none" style="opacity: 0">opportunities that could be of interest to you!</span>
              </p>
              <a @click="toForYourSchool" class="left-btn">
                <button>Read More</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section3 d-lg-block d-none" id="isItem5">
      <div class="container">
        <div class="row item-box">
          <div class="col-12 itemL">
            <img class="mw-100 d-lg-block d-none" src="../../assets/images/Asset 24.png" alt="">
            <img class="mw-100 d-lg-none d-block" src="../../assets/images/Asset 44.png" alt="">
            <div class="blackBox"></div>
            <div class="col-lg-6 col-12 itemP" :class="isItem6 ? 'rollIn6' : ''" v-show="isItem6">
              <div class="col-lg-8 col-12">
                <h3 class="">Specialized Recruitment</h3>
                <h3 class="">Solutions</h3>
              </div>
              <div class="hr"></div>
              <p>
                Global Teacher Recruitment goes beyond just pure recruitment. We tailor and adapt our procedures to suit
                our client's demands. We also assist prospective candidates who may not be TEFL/TESOL/CELTA qualified
                in getting their TEFL qualification through our partnership with TEFL UK.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section3" id="isItem6">
      <div class=" item-box" >
        <div class=" itemL">
          <img class="mw-100 d-lg-block d-none" src="../../assets/images/Asset 24.png" alt="">
          <img class="mw-100 d-lg-none d-block" src="../../assets/images/Asset 44.png" alt="">
          <div class="blackBox"></div>
          <div class=" itemP" :class="isItem6 ? 'rollIn6' : ''" v-show="isItem6">
            <div class="">
              <h3 class="">Specialized Recruitment</h3>
              <h3 class="">Solutions</h3>
            </div>
            <div class="hr"></div>
            <p>
              Global Teacher Recruitment goes beyond just pure recruitment. We tailor and adapt our procedures to suit
              our client's demands. We also assist prospective candidates who may not be TEFL/TESOL/CELTA qualified
              in getting their TEFL qualification through our partnership with TEFL UK.
            </p>
          </div>
        </div>
      </div>
    </div>

    <Footer_Who/>

    <Footer/>

  </div>
</template>
<script>
import Footer from "@/views/Layout/Footer.vue";
import Footer_Who from "@/views/Layout/Footer_Who.vue";

export default {
  components: {Footer_Who, Footer},
  data() {
    return {
      isItem1:true,
      isItem2:false,
      isItem3:false,
      isItem4:false,
      isItem5:false,
      isItem6:false,
      isItem7:false,
      isItem8:false,
      isItem8_1:false,
      isItem8_2:false,
      isItem8_3:false,
      isItem8_4:false,
      isItem8_5:false,
      isItem8_6:false,
      isItem8_7:false,
      isItem8_8:false,
    }
  },
  created() {
  },
  mounted() {
    //添加滚动监听事件
    //在窗口滚动时调用监听窗口滚动方法
    window.addEventListener('scroll', this.windowScrollListener);
  },
  beforeDestroy() {
    // 当组件销毁之前移除事件监听
    window.removeEventListener('scroll', this.windowScrollListener);
  },
  methods: {
    //监听窗口滚动
    windowScrollListener() {
      //获取操作元素最顶端到页面顶端的垂直距离
      var scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
      var windowWidth = window.innerWidth;

      // 获取窗口的高度
      var windowHeight = window.innerHeight;
      // 获取元素
      var isItem1 = document.getElementById('isItem1');
      var isItem2 = document.getElementById('isItem2');
      var isItem3 = document.getElementById('isItem3');
      var isItem4 = document.getElementById('isItem4');
      var isItem5 = document.getElementById('isItem5');
      var isItem6 = document.getElementById('isItem6');
      // 获取元素的位置
      var rollIn1 = isItem1.getBoundingClientRect();
      var rollIn2 = isItem2.getBoundingClientRect();
      var rollIn3 = isItem3.getBoundingClientRect();
      var rollIn4 = isItem4.getBoundingClientRect();
      var rollIn5 = isItem5.getBoundingClientRect();
      var rollIn6 = isItem6.getBoundingClientRect();


      // 计算元素是否在窗口中间
      var isInMiddle1 = rollIn1.top <= windowHeight / 2 && rollIn1.bottom >= windowHeight / 2;
      var isInMiddle2 = rollIn2.top <= windowHeight / 2 && rollIn2.bottom >= windowHeight / 2;
      var isInMiddle3 = rollIn3.top <= windowHeight / 1.2 && rollIn3.bottom >= windowHeight / 2;
      var isInMiddle4 = rollIn4.top <= windowHeight / 2 && rollIn4.bottom >= windowHeight / 2;
      var isInMiddle5 = rollIn5.top <= windowHeight / 2 && rollIn5.bottom >= windowHeight / 2;
      var isInMiddle6 = rollIn6.top <= windowHeight / 2 && rollIn6.bottom >= windowHeight / 2;

      // 如果在窗口中间，执行需要的操作
      if (isInMiddle2) {
        // console.log('元素通过窗口中间触发了！');
        // 执行你需要的代码
        this.isItem2 = true;
        this.isItem3 = true;
      }

      if (isInMiddle3) {
        this.isItem4 = true;
        this.isItem5 = true;
      }
      if (isInMiddle4) {
        this.isItem4 = true;
        this.isItem5 = true;
      }
      if (isInMiddle5) {
        this.isItem6 = true;
      }
      if (isInMiddle6) {
        this.isItem6 = true;
      }
      if (isInMiddle1) {
        this.isItem7 = true;
        this.isItem8 = true;
        this.isItem8_1 = true;
        this.isItem8_2 = true;
        this.isItem8_3 = true;
        this.isItem8_4 = true;
        this.isItem8_5 = true;
        this.isItem8_6 = true;
        this.isItem8_7 = true;
        this.isItem8_8 = true;
      }
      // console.log(isInMiddle1);
    },
    toContact() {
      this.$router.push({ name: 'ContactUs' });
    },
    toForYourSchool() {
      this.$router.push({ name: 'OurServices' });
    }
  }
}
</script>
<style scoped>
.circle200 {
  height: 200px;
  width: 200px;
  background: red;
  border-radius: 50%;
  text-align: center;
  line-height: 200px;
}

/*滚入——从左侧*/
@keyframes rollIn1 {
  0% {
    opacity: 0;
    transform: translate(-100%);
  }
  100% {
    opacity: 1;
  }
}
@keyframes rollIn2 {
  0% {
    opacity: 0;
    transform: translate(-100%);
  }
  100% {
    opacity: 1;
  }
}
@keyframes rollIn3 {
  0% {
    opacity: 0;
    transform: translate(100%);
  }
  100% {
    opacity: 1;
  }
}
@keyframes rollIn4 {
  0% {
    opacity: 0;
    transform: translate(-100%);
  }
  100% {
    opacity: 1;
  }
}
@keyframes rollIn5 {
  0% {
    opacity: 0;
    transform: translate(100%);
  }
  100% {
    opacity: 1;
  }
}
@keyframes rollIn6 {
  0% {
    opacity: 0;
    transform: translate(-100%);
  }
  100% {
    opacity: 1;
  }
}
@keyframes rollIn7 {
  0% {
    opacity: 0;
    transform: translate(-100%);
  }
  100% {
    opacity: 1;
  }
}
@keyframes rollIn8 {
  0% {
    opacity: 0;
    transform: translate(100%);
  }
  100% {
    opacity: 1;
  }
}
@keyframes rollIn8-1 {
  0% {
    opacity: 0;
    transform: translate(100%);
  }
  100% {
    opacity: 1;
  }
}
@keyframes rollIn8-2 {
  0% {
    opacity: 0;
    transform: translate(100%);
  }
  100% {
    opacity: 1;
  }
}
@keyframes rollIn8-3 {
  0% {
    opacity: 0;
    transform: translate(100%);
  }
  100% {
    opacity: 1;
  }
}
@keyframes rollIn8-4 {
  0% {
    opacity: 0;
    transform: translate(100%);
  }
  100% {
    opacity: 1;
  }
}
@keyframes rollIn8-5 {
  0% {
    opacity: 0;
    transform: translate(100%);
  }
  100% {
    opacity: 1;
  }
}
@keyframes rollIn8-6 {
  0% {
    opacity: 0;
    transform: translate(100%);
  }
  100% {
    opacity: 1;
  }
}
@keyframes rollIn8-7 {
  0% {
    opacity: 0;
    transform: translate(100%);
  }
  100% {
    opacity: 1;
  }
}
@keyframes rollIn8-8 {
  0% {
    opacity: 0;
    transform: translate(100%);
  }
  100% {
    opacity: 1;
  }
}

/*滚出——从左侧*/
@keyframes rollOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate(-100%) rotate3d(0, 0, 1, -360deg);
  }
}

/*滚入——从左侧*/
.rollIn1 {
  animation: rollIn1 1s;
}
.rollIn2 {
  animation: rollIn2 1s;
}
.rollIn3 {
  animation: rollIn3 1s;
}
.rollIn4 {
  animation: rollIn4 1s;
}
.rollIn5 {
  animation: rollIn5 1s;
}
.rollIn6 {
  animation: rollIn6 1s;
}
.rollIn7 {
  animation: rollIn7 1.5s;
}
.rollIn8 {
  animation: rollIn8 1s;
}
.rollIn8-1 {
  animation: rollIn8-1 1.5s;
}
.rollIn8-2 {
  animation: rollIn8-2 2s;
}
.rollIn8-3 {
  animation: rollIn8-3 2.5s;
}
.rollIn8-4 {
  animation: rollIn8-4 3s;
}
.rollIn8-5 {
  animation: rollIn8-5 1.5s;
}
.rollIn8-6 {
  animation: rollIn8-6 2s;
}
.rollIn8-7 {
  animation: rollIn8-7 2.5s;
}
.rollIn8-8 {
  animation: rollIn8-8 3s;
}



/*滚出——从左侧*/
.rollOut {
  animation: rollOut 1s;
}
</style>