<template>
  <div class="DestinationGuides">

    <div class="section1">
      <div class="itemLeft d-lg-none d-block">
        <h1 class="">Destination</h1>
        <h1 class="">Guides</h1>
        <div class="hr"></div>
        <p>
          With so many options available, it can be difficult to decide where to start your next adventure.
          We have exciting opportunities in Hong Kong and Macau with endless possibilities for exploring Asia!
        </p>
      </div>
      <img class="mw-100 d-lg-block d-none" src="../../assets/images/destination-guides/Asset 24.png" alt="">
      <div class="d-lg-none d-block" style="position: relative">
        <img class="mw-100" src="../../assets/images/destination-guides/mobile_Asset 37.png" alt="">
        <div class="btn-box">
          <button class="left-btn active" @click="goToHongKong">Hong Kong</button>
          <button class="right-btn" @click="goToMacau">Macau</button>
        </div>
      </div>
      <div class="itemLeft d-lg-block d-none">
        <h1 class="">Destination</h1>
        <h1 class="">Guides</h1>
        <div class="hr"></div>
        <p>
          With so many options available, it can be difficult to decide where to start your next adventure.
          We have exciting opportunities in Hong Kong and Macau with endless possibilities for exploring Asia!
        </p>
        <div class="btn-box">
          <button class="left-btn active" @click="goToHongKong">Hong Kong</button>
          <button class="right-btn" @click="goToMacau">Macau</button>
        </div>
      </div>
    </div>

    <div class="section2">
      <img class="mw-100 header-img d-lg-block d-none" src="../../assets/images/destination-guides/hongkong_Asset 25.png" alt="">
      <img class="mw-100 header-img d-lg-none d-block" src="../../assets/images/destination-guides/mobile_Asset 38.png" alt="">
      <div class="center-box">
        <h1 class="">Hong Kong</h1>
        <p>
          Hong Kong is one the most modern
          and culturally diverse cities in South
          East Asia. Thanks to a mixture of
          Western and traditional Asian
          inuences, Hong Kong is an ideal city
          for any aspiring ESL teacher who wants
          to experience traditional Asian culture,
          cuisine, and lifestyle, while still having
          enough Western inuence to make you
          feel right at home.
        </p>
      </div>
    </div>

    <div class="section3">
      <div class="section3-1">
        <div class="left-box">
          <div class="item">
            <h1 class="">Lantau Island and</h1>
            <h1 class="">Giant Buddha Day Trip</h1>
            <p>
              Pay a visit to the Giant Buddha, visit Po Lin (Precious Lotus) Monastery and see the sights of Lantau on
              this unbelievable tour, complete with a qualified and welcoming guide. You’ll get a taste of the stunning beaches,
              fishing villages, and spectacular mountain ranges that make this destination so exceptional.
            </p>
          </div>
        </div>
        <div class="center-box">
          <img class="mw-100 d-lg-block d-none" src="../../assets/images/destination-guides/hongkong_Asset 26.png" alt="">
          <img class="mw-100 d-lg-none d-block" src="../../assets/images/destination-guides/mobile_Asset 44.png" alt="">
        </div>
        <div class="right-box">
          <div class="item">
            <h1 class="">Victoria Peak</h1>
            <p>
              Victoria Peak is a hill on the western half of Hong Kong Island. It is also known as Mount Austin,
              and locally as The Peak. The esteemed Peak Tram is, as most passengers agree, the only way to experience
              the exquisiteness of Hong Kong’s natural wonders. Tens of millions of individuals from every corner of
              the world have taken the ride, which affords an exceptional view of the city.
            </p>
          </div>
        </div>
      </div>
      <div class="section3-2">
        <div class="left-box">
          <img class="mw-100 d-lg-block d-none" src="../../assets/images/destination-guides/hongkong_Asset 27.png" alt="">
          <img class="mw-100 d-lg-none d-block" src="../../assets/images/destination-guides/mobile_Asset 43.png" alt="">
        </div>
        <div class="right-box">
          <div class="item">
            <h1 class="">Avenue of Stars</h1>
            <p>
              Thanks to the efforts of Hong Kong’s movie industry over the past era The Avenue of Stars pays tribute to
              the names that helped make Hong Kong the “Hollywood of the East”.
            </p>
          </div>
        </div>
      </div>
      <div class="section3-3">
        <div class="left-box">
          <img class="mw-100 d-lg-block d-none" src="../../assets/images/destination-guides/Asset 52_HK_n.png" alt="">
          <img class="mw-100 d-lg-none d-block" src="../../assets/images/destination-guides/mobile_Asset 42.png" alt="">
        </div>
        <img class="mw-100 d-lg-none d-block" src="../../assets/images/destination-guides/mobile_Asset 39.png" alt="">
        <div class="right-box">
          <div class="item">
            <h1 class="">Cuisine</h1>
            <p>
              Given Hong Kong’s status as a former British colony, it was expected that the local Chinese population would develop
              a sense of taste for Western food. Many appreciate a traditional breakfast that includes congee (rice porridge)
              and you cha kwai (oil-fried bread sticks). However, Western breakfasts that include bread, sausage, pancakes, and eggs are becoming more popular.
            </p>
          </div>
          <img class="mw-100 d-lg-block d-none" src="../../assets/images/destination-guides/hongkong_Asset 29.png" alt="">
          <img class="mw-100 d-lg-none d-block" src="../../assets/images/destination-guides/mobile_Asset 40.png" alt="">
        </div>
      </div>
      <div class="section3-1">
        <div class="left-box">
          <div class="item">
            <h1 class="">Weather</h1>
            <p>
              Hong Kong has a humid subtropical climate. Winters are mild and lower in humidity. Early winter is usually sunny,
              becoming cloudier in February with Summer being extremely humid and reaching a maximum of 31 degrees.
            </p>
          </div>
        </div>
        <div class="center-box">
          <img class="mw-100 d-lg-block d-none" src="../../assets/images/destination-guides/hongkong_Asset 30.png" alt="">
          <img class="mw-100 d-lg-none d-block" src="../../assets/images/destination-guides/mobile_Asset 41.png" alt="">
        </div>
        <div class="right-box rightBox">
          <div class="item">
            <h1 class="">Public Transportation</h1>
            <p>
              You can get to most places efficiently on the MTR (subway). There are also heaps of buses and bus routes,
              so you can get to some remote locations as well. Then you have the option of trains, minivans, coaches, ferries, taxis, etc.
            </p>
          </div>
          <div class="item">
            <h1 class="">Airports</h1>
            <p>Hong Kong International Airport with Hong Kong Airport Express.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="section4">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-7 col-12">
            <h1>Country Facts - Cost Of Living</h1>
            <ul>
              <li>
                Hong Kong is one of the most expensive places in the world. Rent pushes up the cost of living significantly –
                but if you choose your new home wisely, you can still live an excellent life on an average teacher salary.
              </li>
              <li>
                The cost of a combo meal at a fast-food restaurant starts at 35 HKD and a meal in a local restaurant can start at 50 HKD.
                Street vendors offer cheaper meals and snacks and grocery shopping can cost around 1000 HKD per week.
              </li>
              <li>Utility costs can be between 300 HKD and 500 HKD per month.</li>
              <li>A gym membership costs around 580 HKD per month.</li>
            </ul>
            <p>
              Between 6,000 HKD and 10,500 HKD per month along with one month of rent as a deposit should you choose dorm living? In some cases,
              three months of rent is required upfront with a deposit should you wish to find your own apartment.
            </p>
          </div>
        </div>
      </div>
    </div>

    <Footer/>
  </div>
</template>

<script>
import Footer from "@/views/Layout/Footer.vue";
import store from "@/store";
export default {
  components: {Footer},
  data() {
    return {

    }
  },
  created() {
  },
  mounted() {

  },
  methods: {
    goToHongKong() {
      this.$router.push({path:'/DestinationGuides/HongKong',query:{name:'/DestinationGuides/HongKong'}})
    },
    goToMacau() {
      this.$router.push({path:'/DestinationGuides/Macau',query:{name:'/DestinationGuides/Macau'}})
    }
  }
}
</script>


<style scoped>

</style>