export default {
    menu: {
        home: '数据统计',
        goods: '商品管理',
        params: '规格参数'
    },
    home:{

    },
    news:{
        
    }
}