<template>
  <div class="content-footer">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-12 leftBox">
          <div class="left-img">
            <img class="mw-100 header-img" src="../../assets/images/gtr-logo.png" alt="">
          </div>
          <div class="left-center">
            <h3>LIVE AND</h3>
            <h3>WORK ABROAD</h3>
          </div>
          <div @click="goContactUs" class="left-btn">
             <button>Contact Us</button>
          </div>
        </div>
        <div class="col-lg-6 col-12 rightBox">
          <div class="item-p">
            <p>Suite 2514, 25/F, 1111 King’s Road,</p>
            <p>Taikoo Shing, Hong Kong</p>
          </div>
          <div class="item-p">
            <p>The Wanderers Building, The Oval Office Park,</p>
            <p>CNR Meadowbrook Lane & Sloane Street,</p>
            <p>Bryanston, South Africa</p>
          </div>
          <div class="item-p">
            <p>Tel: (+27) 10 442 2432</p>
            <p>Email: Info@globalteacherrecruitment.com</p>
          </div>
          <div class="fb-ico">
            <a href="https://www.facebook.com/globalteacherrecruitment/" target="_blank">
              <img class="mw-100 header-img" src="../../assets/images/fb-ico.png" alt="">
            </a>
            <a href="https://www.instagram.com/globalteacherrecruitment/" target="_blank">
              <img class="mw-100 header-img" src="../../assets/images/ig-ico.png" alt="">
            </a>
            <a href="javaScript:;" target="_blank">
              <img class="mw-100 header-img" src="../../assets/images/twitter-ico.png" alt="">
            </a>
            <a href="javaScript:;" target="_blank">
              <img class="mw-100 header-img" src="../../assets/images/youtube-ico.png" alt="">
            </a>
            <a href="https://www.linkedin.com/company-beta/17993514/" target="_blank">
              <img class="mw-100 header-img" src="../../assets/images/ind-ico.png" alt="">
            </a>
<!--            <a href="https://www.pinterest.co.uk/globalteacherrecruitment" target="_blank">-->
<!--              <img class="mw-100 header-img" src="../../assets/images/pinterest-ico.png" alt="">-->
<!--            </a>-->
            <a href="https://www.tiktok.com/@gtr_recruitment" target="_blank">
              <img class="mw-100 header-img" src="../../assets/images/tittok-ico.png" alt="">
            </a>
          </div>
        </div>
        <div class="col-12 footer-bottomBox">
          <div class="col-lg-6 col-12">Global Teacher Recruitment © Copyright 2024 All rights reserved.</div>
          <div class="col-lg-6 col-12 item-p">Privacy Statement | Terms of Use</div>
        </div>
        <div class="hr-box"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import $ from "jquery"
export default {
  props: ["isCollapse"],
  data() {
    return {
    }

  },
  computed: {
    ...mapState("loginModule", ["userinfo"]),
  },
  created() {

  },
  methods: {
    ...mapMutations("loginModule", ["clearUser"]),

    goContactUs() {
      // this.$router.push({path:'/ContactUs'})
      this.$router.push({ name: 'ContactUs' });
    }

  },
};
</script>

<style lang='less' scoped>
@import url("../../assets/css/paopao.css");

</style>