<template>
  <div class="layout">
 

    <!-- 左侧导航区域 -->
<!--    <MyMenu class="menu" :isCollapse="isCollapse" />-->
    <!-- 顶部导航栏区域 -->
<!--    <Content-->
<!--      class="content"-->
<!--      :class="{ isActive: isCollapse }"-->
<!--      :isCollapse="isCollapse"-->
<!--    />-->
    <Content class="content"/>
  
  </div>
</template>

<script>
import MyMenu from "./Mymenu.vue";
import Content from "./Content.vue";
export default {
  components: {
    MyMenu,
    Content,
  },
  data() {
    return {

    };
  },
  methods: {

  },

};
</script>

<style lang='less' scoped>
.layout {
  .menu {
    // width: 200px;
    // min-height: 500px;
    background: #112f50;
    position: fixed;
    top: 0;
    bottom: 0;
  }
  .content {
    margin-left: 0px;
    transition: all 0.3s;
  }
  .isActive {
    margin-left: 64px;
  }
}
</style>