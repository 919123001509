<template>
  <div class="home">
    <div class="section4" id="isItem1">
      <div class="container">
        <div class="row">
          <div class="col-12 " style="height: 50px" v-show="!isItem7"></div>
          <div class="col-12 d-flex whoBox">
            <div class="col-lg-3 col-12 item-who" :class="isItem7 ? 'rollIn7' : ''" v-show="isItem7">
              <p>Who We</p>
              <p>Work With</p>
            </div>
            <div class="col-lg-8 log_box" :class="isItem8 ? 'rollIn8' : ''" v-show="isItem8">
              <a href="javaScript:;" :class="isItem8_1 ? 'rollIn8-1' : ''" v-show="isItem8_1"><img class="mw-100 " src="../../assets/images/ef-logo.png" alt=""></a>
              <a href="javaScript:;" :class="isItem8_2 ? 'rollIn8-2' : ''" v-show="isItem8_2"><img class="mw-100 " src="../../assets/images/wallstreet-logo.png" alt=""></a>
              <a href="javaScript:;" :class="isItem8_3 ? 'rollIn8-3' : ''" v-show="isItem8_3"><img class="mw-100 item-img01" src="../../assets/images/mrenglish-logo.png" alt=""></a>
              <a href="javaScript:;" :class="isItem8_4 ? 'rollIn8-4' : ''" v-show="isItem8_4"><img class="mw-100 item-img03" src="../../assets/images/tefl-logo.png" alt=""></a>
              <a href="javaScript:;" :class="isItem8_5 ? 'rollIn8-5' : ''" v-show="isItem8_5"><img class="mw-100 item-img01" src="../../assets/images/sisbangkok-logo.png" alt=""></a>
              <a href="javaScript:;" :class="isItem8_6 ? 'rollIn8-6' : ''" v-show="isItem8_6"><img class="mw-100 item-img02" src="../../assets/images/wahaha-logo.png" alt=""></a>
              <a href="javaScript:;" :class="isItem8_7 ? 'rollIn8-7' : ''" v-show="isItem8_7"><img class="mw-100 item-img01" src="../../assets/images/hess-logo.png" alt=""></a>
              <a href="javaScript:;" :class="isItem8_8 ? 'rollIn8-8' : ''" v-show="isItem8_8">and more...</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  components: {},
  data() {
    return {
      isItem7:false,
      isItem8:false,
      isItem8_1:false,
      isItem8_2:false,
      isItem8_3:false,
      isItem8_4:false,
      isItem8_5:false,
      isItem8_6:false,
      isItem8_7:false,
      isItem8_8:false,
    }
  },
  created() {

  },
  mounted() {
    //添加滚动监听事件
    //在窗口滚动时调用监听窗口滚动方法
    window.addEventListener('scroll', this.windowScrollListener);
  },
  beforeDestroy() {
    // 当组件销毁之前移除事件监听
    window.removeEventListener('scroll', this.windowScrollListener);
  },
  methods: {
    //监听窗口滚动
    windowScrollListener() {
      //获取操作元素最顶端到页面顶端的垂直距离
      var scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
      var windowWidth = window.innerWidth;

      // 获取窗口的高度
      var windowHeight = window.innerHeight;
      // 获取元素
      var isItem1 = document.getElementById('isItem1');
      // 获取元素的位置
      var rollIn1 = isItem1.getBoundingClientRect();



      // 计算元素是否在窗口中间
      var isInMiddle1 = rollIn1.top <= windowHeight / 2 && rollIn1.bottom >= windowHeight / 2;

      // 如果在窗口中间，执行需要的操作
      if (isInMiddle1) {
        this.isItem7 = true;
        this.isItem8 = true;
        this.isItem8_1 = true;
        this.isItem8_2 = true;
        this.isItem8_3 = true;
        this.isItem8_4 = true;
        this.isItem8_5 = true;
        this.isItem8_6 = true;
        this.isItem8_7 = true;
        this.isItem8_8 = true;
      }
    },
  }
}
</script>
<style scoped>
.circle200 {
  height: 200px;
  width: 200px;
  background: red;
  border-radius: 50%;
  text-align: center;
  line-height: 200px;
}

/*滚入——从左侧*/
@keyframes rollIn7 {
  0% {
    opacity: 0;
    transform: translate(-100%);
  }
  100% {
    opacity: 1;
  }
}
@keyframes rollIn8 {
  0% {
    opacity: 0;
    transform: translate(100%);
  }
  100% {
    opacity: 1;
  }
}
@keyframes rollIn8-1 {
  0% {
    opacity: 0;
    transform: translate(100%);
  }
  100% {
    opacity: 1;
  }
}
@keyframes rollIn8-2 {
  0% {
    opacity: 0;
    transform: translate(100%);
  }
  100% {
    opacity: 1;
  }
}
@keyframes rollIn8-3 {
  0% {
    opacity: 0;
    transform: translate(100%);
  }
  100% {
    opacity: 1;
  }
}
@keyframes rollIn8-4 {
  0% {
    opacity: 0;
    transform: translate(100%);
  }
  100% {
    opacity: 1;
  }
}
@keyframes rollIn8-5 {
  0% {
    opacity: 0;
    transform: translate(100%);
  }
  100% {
    opacity: 1;
  }
}
@keyframes rollIn8-6 {
  0% {
    opacity: 0;
    transform: translate(100%);
  }
  100% {
    opacity: 1;
  }
}
@keyframes rollIn8-7 {
  0% {
    opacity: 0;
    transform: translate(100%);
  }
  100% {
    opacity: 1;
  }
}
@keyframes rollIn8-8 {
  0% {
    opacity: 0;
    transform: translate(100%);
  }
  100% {
    opacity: 1;
  }
}


/*滚入——从左侧*/
.rollIn7 {
  animation: rollIn7 1.5s;
}
.rollIn8 {
  animation: rollIn8 1s;
}
.rollIn8-1 {
  animation: rollIn8-1 1.5s;
}
.rollIn8-2 {
  animation: rollIn8-2 2s;
}
.rollIn8-3 {
  animation: rollIn8-3 2.5s;
}
.rollIn8-4 {
  animation: rollIn8-4 3s;
}
.rollIn8-5 {
  animation: rollIn8-5 1.5s;
}
.rollIn8-6 {
  animation: rollIn8-6 2s;
}
.rollIn8-7 {
  animation: rollIn8-7 2.5s;
}
.rollIn8-8 {
  animation: rollIn8-8 3s;
}
</style>