export default {
    //页面的英文内容
    menu: {
        home: 'Data Statistics',
        goods: 'Goods Manage',
        params: 'Specification'
    },
    home:{

    },
    news:{
        
    }
}