import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';
import './plugins/element.js'
import './assets/css/reset.css'
import './assets/css/iconfont.css'
import './assets/css/main.css'
import './assets/css/responsive.css'
import './assets/bootstrap-5.3.3-dist/css/bootstrap.css'
import api from './api/index'
Vue.prototype.$api = api;
import i18n from './lang/index'
import './router/permission'
import './utils/localStorage'
//echarts
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts;
import VueTour from 'vue-tour'

require('vue-tour/dist/vue-tour.css')

Vue.config.productionTip = false

// router.afterEach(() => {
//   // 在每次路由变化后，滚动到页面顶部
//   window.scrollTo(0, 0);
// });

// 全局注册
Vue.use(VueTour)
Vue.use(Vant);


new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
