<template>
  <div>
    <div class="content-header">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-6 d-flex align-items-center">
            <img class="mw-100 header-img" src="../../assets/images/gtr-logo.png" alt="">
          </div>
          <div class="col-6 text-right d-lg-none d-block">
            <a href="javaScript:;" class="menu-btn" @click="toggleContent">
              <svg class="svg-inline--fa fa-list fa-w-16" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="list" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                <path style="color: #FFFFFF" fill="currentColor" d="M80 368H16a16 16 0 0 0-16 16v64a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16v-64a16 16 0 0 0-16-16zm0-320H16A16 16 0 0 0 0 64v64a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16V64a16 16 0 0 0-16-16zm0 160H16a16 16 0 0 0-16 16v64a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16v-64a16 16 0 0 0-16-16zm416 176H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-320H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16V80a16 16 0 0 0-16-16zm0 160H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16z"></path>
              </svg><!-- <i class="fas fa-list"></i> --></a>
          </div>
          <div class="col-lg-9 col-12 d-lg-block d-none">
            <el-menu
                :default-active="$route.path"
                router
                class="el-menu-demo d-lg-block"
                mode="horizontal"
                @select="handleSelect"
                background-color="#981317"
                text-color="#fff"
                border-bottom="0px"
                active-text-color="#ffd04b">
              <el-menu-item index="/" >Home</el-menu-item>
              <el-menu-item index="/AboutUs" >About Us</el-menu-item>
<!--              <el-menu-item index="/OurServices" >Our Services</el-menu-item>-->
              <el-submenu index="/OurServices">
                <template slot="title">Our Services</template>
                <el-menu-item index="/OurServices/OurServices_FYS">For Your School</el-menu-item>
                <el-menu-item index="/OurServices/OurServices_TA">Teach Abroad</el-menu-item>
              </el-submenu>
              <el-menu-item index="/DestinationGuides/HongKong" >Destination Guides</el-menu-item>
              <el-menu-item index="/TEFL" >TEFL</el-menu-item>
              <el-menu-item index="/Vacancies" >Vacancies</el-menu-item>
              <el-menu-item index="/Reviews" >Reviews</el-menu-item>
              <el-menu-item index="/ContactUs" >Contact Us</el-menu-item>

              <!--              <div class="header-right">-->
              <!--                <el-dropdown @command="clickLang">-->
              <!--          <span class="el-dropdown-link" style="color: #fff">-->
              <!--                 多语言<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
              <!--          </span>-->
              <!--                  <el-dropdown-menu slot="dropdown">-->
              <!--                    <el-dropdown-item command="zh">中文</el-dropdown-item>-->
              <!--                    <el-dropdown-item command="en">English</el-dropdown-item>-->
              <!--                  </el-dropdown-menu>-->
              <!--                </el-dropdown>-->

              <!--                &lt;!&ndash;        <div class="user">&ndash;&gt;-->
              <!--                &lt;!&ndash;          <span > 欢迎：{{ userinfo.user }}</span>&ndash;&gt;-->
              <!--                &lt;!&ndash;          <i class="iconfont icon-tuichu" @click="loginout"></i>&ndash;&gt;-->
              <!--                &lt;!&ndash;        </div>&ndash;&gt;-->
              <!--              </div>-->
            </el-menu>
          </div>
          <div class="col-lg-9 col-12 d-lg-none d-block">
            <el-menu
                :default-active="$route.path"
                router
                class="el-menu-demo d-lg-block"
                mode="horizontal"
                @select="handleSelect"
                background-color="#981317"
                text-color="#fff"
                border-bottom="0px"
                active-text-color="#ffd04b">
              <el-menu-item index="/" @click="toggleContent">Home</el-menu-item>
              <el-menu-item index="/AboutUs" @click="toggleContent">About Us</el-menu-item>
<!--              <el-menu-item index="/OurServices" @click="toggleContent">Our Services</el-menu-item>-->
              <el-submenu index="/OurServices" @click="toggleContent">
                <template slot="title">Our Services</template>
                <el-menu-item index="/OurServices/OurServices_FYS">For Your School</el-menu-item>
                <el-menu-item index="/OurServices/OurServices_TA">Teach Abroad</el-menu-item>
              </el-submenu>
              <el-menu-item index="/DestinationGuides/HongKong" @click="toggleContent">Destination Guides</el-menu-item>
              <el-menu-item index="/TEFL" @click="toggleContent">TEFL</el-menu-item>
              <el-menu-item index="/Vacancies" @click="toggleContent">Vacancies</el-menu-item>
              <el-menu-item index="/Reviews" @click="toggleContent">Reviews</el-menu-item>
              <el-menu-item index="/ContactUs" @click="toggleContent">Contact Us</el-menu-item>

              <!--              <div class="header-right">-->
              <!--                <el-dropdown @command="clickLang">-->
              <!--          <span class="el-dropdown-link" style="color: #fff">-->
              <!--                 多语言<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
              <!--          </span>-->
              <!--                  <el-dropdown-menu slot="dropdown">-->
              <!--                    <el-dropdown-item command="zh">中文</el-dropdown-item>-->
              <!--                    <el-dropdown-item command="en">English</el-dropdown-item>-->
              <!--                  </el-dropdown-menu>-->
              <!--                </el-dropdown>-->

              <!--                &lt;!&ndash;        <div class="user">&ndash;&gt;-->
              <!--                &lt;!&ndash;          <span > 欢迎：{{ userinfo.user }}</span>&ndash;&gt;-->
              <!--                &lt;!&ndash;          <i class="iconfont icon-tuichu" @click="loginout"></i>&ndash;&gt;-->
              <!--                &lt;!&ndash;        </div>&ndash;&gt;-->
              <!--              </div>-->
            </el-menu>
          </div>
        </div>
      </div>
    </div>
    <!-- 内容区域---路由出口 -->
    <div class="content">
      <router-view />
    </div>
    <!-- ---------新手引导-------------------------------------- -->
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import $ from "jquery"
export default {
  props: ["isCollapse"],
  data() {
    return {
      activeIndex: '1',
    }
  },
  computed: {
    ...mapState("loginModule", ["userinfo"]),
  },
  created() {
    // console.log( $ );
  },
  methods: {
    ...mapMutations("loginModule", ["clearUser"]),

    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    toggleContent() {
      $('.content-header .el-menu-demo').toggle(500)

    },
    clickLang(command) {
      console.log(command);
      this.$i18n.locale = command;
    },
    loginout() {
      //退出登录
      //清空vuex数据
      this.clearUser();
      //清空本地存储
      localStorage.removeItem("user");
      //返回登录
      this.$router.push("/login");
    },
  },
};
</script>

<style lang='less' scoped>
@import url("../../assets/css/paopao.css");

//.header {
//  height: 50px;
//  line-height: 50px;
//  color: #fff;
//  background: #1e78bf;
//  .iconfont {
//    font-size: 24px;
//  }
//}
//.header-right {
//  float: right;
//  padding-right: 20px;
//  display: flex;
//  .user {
//    margin-left: 20px;
//    span {
//      position: relative;
//      top: -4px;
//      margin-right: 10px;
//    }
//  }
//}
</style>