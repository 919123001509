import router from './index'
//获取vuex数据
import store from '../store/index'
//路由拦截
// router.beforeEach((to,from,next)=>{
//   //1. 判断是否需要登录
//   if(to.matched.some(ele=>ele.meta.isLogin)){
//       //2. 判断当前的用户是否已经登录
//       let token=store.state.loginModule.userinfo.token;
//       if(token){
//         next()
//       }else{
//         next('/login')
//       }
//   }else{// 不需要登录
//     next();
//   }
// })

// 导航守卫限制路由跳转
router.beforeEach((to, from, next) => {
    if (to.path === '/login') {
        next()
        var tokenStr = store.state.loginModule.userinfo.token;
    }
    if (!tokenStr) {
        next()
    } else {
        next('/login')
    }
})
// router  要跟你引用的路由名称一致